CMS = CMS || {};

const configureationFrom = () => {
  const loads = ["handle_form_on_load"];

  const handleFormOnLoad = () => {
    const pageForm = document.querySelector("#js-configuration-form");

    if (!pageForm) return false;

    const radioButtons = pageForm.querySelectorAll(".js-check-box-clickers");

    handleSelectForms(pageForm);

    radioButtons.forEach((radioButton) => {
      radioButton.addEventListener("click", async () => {
        handleSelectForms(pageForm);
      });
    });
  };

  const handleSelectForms = (pageForm) => {
    const checkedValue = pageForm.querySelector("input[type=radio]:checked").value;

    if (checkedValue === "stock_smartlists" || checkedValue === "none") {
      pageForm.querySelector("#js-hide-stock-collection-publish-setup").classList = "hidden";
      if (pageForm.querySelector("#js-hide-stock-version-radio-buttons"))
        pageForm.querySelector("#js-hide-stock-version-radio-buttons").classList = "hidden";
      if (pageForm.querySelector("#js-hide-stock-version-header"))
        pageForm.querySelector("#js-hide-stock-version-header").classList = "hidden";
    } else {
      pageForm.querySelector("#js-hide-stock-collection-publish-setup").classList = "col-wrapper-2";
      if (pageForm.querySelector("#js-hide-stock-version-radio-buttons"))
        pageForm.querySelector("#js-hide-stock-version-radio-buttons").classList = "check-wrapper-hor";
      if (pageForm.querySelector("#js-hide-stock-version-header"))
        pageForm.querySelector("#js-hide-stock-version-header").classList = "col-wrapper-2";
    }
  };

  return {
    loads: loads,
    handle_form_on_load: handleFormOnLoad,
  };
};

CMS.configureationFrom = configureationFrom();
